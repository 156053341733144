import React from "react";
import {
  ExistingMembershipPlanCard,
} from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { AppUtils } from "../../../utils/app-utils";
import { connect } from "react-redux";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
import {
  addToShoppingCartItems,
} from "../../../redux/reducers/membership-purchase-reducer";


class ExistingMembershipComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      subscribedProducts: [],
      combinedMedVetProduct: undefined,
      annualProduct: undefined,
      winbackProduct: undefined,
      isCombinedAutoRenewSelected: false,
      isAnnualAutoRenewSelected: false,
      petMedRecId: "",
      vetHelpRecId: "",
    };
    this.cRef = React.createRef();
  }

  fetchMembershipProductsOfPet() {
    axios
      .processGet(
        `${ApiConstants.URL_GET_MEMBERSHIP_PRODUCTS_OF_PET
        }/${AppUtils.getBusRelAccount()}/${this.props.selectedPetId}`,
        this.cRef
      )
      .then((response) => {
        const data = response.data;
        let isCombinedAutoRenewSelected = false;
        let combinedMedVetProduct = undefined;
        let isAnnualAutoRenewSelected = false;
        let annualProduct = undefined;
        let winbackProduct = undefined;
        let petMedRecId = "";
        let vetHelpRecId = ""
        const subscribedProducts = [];
        data.productList.forEach((product) => {
          let isSubscriptionProduct = 0;
          let isLPMBundleProduct = 0;
          let isAnnualProduct = 0;
          let logoText = null;
          if (
            AppUtils.isEqualString(
              AppUtils.getAlreadyPurchasedAnnualProductName(product.itemId),
              'Annual Protection Membership'
            )
          ) {
            isAnnualProduct = product.isDisplayed;
          }
          if (AppUtils.isEqualString(product.itemId, "LPM Bundle Product")) {
            isLPMBundleProduct = product.isDisplayed;
          }
          if (
            AppUtils.isEqualString(product.itemId, "PetMedInfo Fees") ||
            AppUtils.isEqualString(product.itemId, "PetMedAlert_Sub")
          ) {
            isSubscriptionProduct = product.isDisplayed;

            if (AppUtils.isEqualString(product.itemId, "PetMedInfo Fees")) {
              petMedRecId = product?.recId;
            }
          }
          if (
            AppUtils.isEqualString(product.itemId, "WD Annual Membership") ||
            AppUtils.isEqualString(product.itemId, "WhiskerDoc_Service")
          ) {
            isSubscriptionProduct = product.isDisplayed;
            logoText = "vetHelp";

            if (AppUtils.isEqualString(product.itemId, "WD Annual Membership")) {
              vetHelpRecId = product?.recId;
            }
          }
          if (AppUtils.isEqualString(product.itemId, "DirectConnect")) {
            isSubscriptionProduct = product.isDisplayed;
          }
          if (product.isDisplayed && AppUtils.isEqualString(product.itemId, "Petcademy_Subption")) {
            isSubscriptionProduct = product.isDisplayed;
            logoText = "petcademy"
          }

          if (isSubscriptionProduct) {
            subscribedProducts.push({
              productName: product.itemUIName,
              price: AppUtils.getConvertedPrice(product.price),
              frequency: product.isSubscribed ? 'Per Month' : 'Per Year',
              date: product.renewDate,
              logo: logoText,
              expiryDate: product.expiryDate,
              isCancelled: !!product.isCanceled,
            })
          }

          if (isLPMBundleProduct) {
            isCombinedAutoRenewSelected = product.autoRenew;
            combinedMedVetProduct = {
              productName: product.itemUIName,
              price: AppUtils.getConvertedPrice(product.price),
              frequency: 'Per Year',
              date: product.renewDate,
              expiryDate: product.expiryDate,
            }
          }

          if (isAnnualProduct) {
            isAnnualAutoRenewSelected = product.autoRenew;
            annualProduct = {
              productName:  AppUtils.getAlreadyPurchasedAnnualProductName(product.itemId),
              price: AppUtils.getConvertedPrice(product.price),
              frequency: 'Per Year',
              date: product.renewDate,
              expiryDate: product.expiryDate,
              recId: product.recId,
              itemId: product.itemId,
            }
          }
        })

        if (this.props.winbackBundle) {
          winbackProduct = {
            expiryDate:this.props?.winbackBundle?.offerEndDate,
            offerPrice:AppUtils.getConvertedPrice(this.props?.winbackBundle?.itemPrice)
          }
        }

        this.setState({
          subscribedProducts,
          combinedMedVetProduct,
          annualProduct,
          winbackProduct,
          isCombinedAutoRenewSelected,
          isAnnualAutoRenewSelected,
          petMedRecId,
          vetHelpRecId
        })
      });
  }

  saveAutoRenewInfo(item) {
    axios
      .processPost(ApiConstants.URL_SAVE_AUTO_RENEW_POST, null, {
        email: AppUtils.getUserEmail(),
        busRelAccount: AppUtils.getBusRelAccount(),
        animalId: this.props.selectedPetId,
        itemId: item.itemId,
        recId: item.recId,
        autoRenew: item.autoRenew,
      })
      .then((response) => {})
      .catch((err) => {});
  }

  addWinbackOfferToCart = (e) => {
    const el = document.getElementById("pet-shopping-cart");

    const item = {
      itemId: this.props?.winbackBundle.itemId,
      itemType: this.props?.winbackBundle.itemType,
      productName: AppUtils.getProductNameFromItemId(
        this.props?.winbackBundle.itemId
      ),
      productCost: AppUtils.getConvertedPrice(
        this.props?.winbackBundle.itemPrice
      ),
      bundleList: this.props?.winbackBundle.bundleList,
    };
    DataLayerUtils.addToCartEvent(item);
    this.props.addToShoppingCartItems({
      selectedPetId: this.props.selectedPetId,
      item,
    });

    if(el) {
      el.scrollIntoView({behavior: "smooth"});
    }

    e.preventDefault();
  }

  componentDidMount() {
    this.fetchMembershipProductsOfPet();
  }

  getMembershipProductDetails() {
    if (this.props.memberType === 2 ||
      this.props.memberType === 3
    ) {
      if (this.props.isLPMPlusProduct) {
        return {
          productName: 'Lifetime Protection Membership Plus',
          productBenefits: [
            "Connect with your pet's finder and arrange a quick, safe reunion using DirectConnect.",
            "Receive a $25 Petco coupon, redeemable for in-store purchases.",
            "Reach veterinary professionals anytime by phone, email or live chat powered by whiskerDocs® (complimentary for 1 year)",
            "With 24PetMedAlert®, critical medical and behavioral information will be shared with the shelter or vet the pet is brought to when found (complimentary for 1 year)",
            "Access to Lost Pet Recovery Specialists who can quickly launch the process of finding your pet",
            "Save on pet-sitting and dog walking services with a $30 Rover coupon.",
            "Customized metal ID tags with all of your contact information to make reunification easier",
            "Customized training with easy to follow video lessons for you and your pet, powered by Petcademy"
          ]
        }
      }

      return {
        productName: 'Lifetime Protection Membership',
        productBenefits: [
          "Connect with your pet's finder and arrange a quick, safe reunion using DirectConnect.",
          "Receive a $25 Petco coupon, redeemable for in-store purchases.",
          "Reach veterinary professionals anytime by phone, email or live chat powered by whiskerDocs® (complimentary for 1 year)",
          "With 24PetMedAlert®, critical medical and behavioral information will be shared with the shelter or vet the pet is brought to when found (complimentary for 1 year)",
          "Access to Lost Pet Recovery Specialists who can quickly launch the process of finding your pet",
          "Save on pet-sitting and dog walking services with a $30 Rover coupon."
        ]
      }
    }

    return {};
  }
  getMembershipCard() {
    const membershipPlan = this.getMembershipProductDetails();
    if (this.state.subscribedProducts.length === 0 &&
      Object.keys(membershipPlan).length === 0 &&
      !this.state.annualProduct
    ) {
      return <div></div>
    }

    return (
      <ExistingMembershipPlanCard
        membershipProduct={membershipPlan}
        subscriptionProducts={this.state.subscribedProducts}
        combinedMedVetProduct={this.state.combinedMedVetProduct}
        annualProduct={this.state.annualProduct}
        winbackProduct={this.state.winbackProduct}
        renewButtonProps={{
          id: "winback-renew-button-id",
          onClick: (e) => {
            this.addWinbackOfferToCart(e);
          }
        }}
        autoRenewCheckboxProps={{
          id: "auto-renew-product",
          showCheckbox: (
            !AppUtils.isEqualString(this.state.petMedRecId, '0') &&
            !AppUtils.isEqualString(this.state.vetHelpRecId, '0')
          ),
          checked: this.state.isCombinedAutoRenewSelected,
          onChange: () => {
            this.setState({
              isCombinedAutoRenewSelected: !this.state.isCombinedAutoRenewSelected
            }, () => {
              this.saveAutoRenewInfo({
                itemId: "PetMedInfo Fees,WD Annual Membership",
                recId: `${this.state.petMedRecId},${this.state.vetHelpRecId}`,
                autoRenew: this.state.isCombinedAutoRenewSelected,
              });
            })
          },
        }}
        annualAutoRenewCheckboxProps={{
          id: "annual-auto-renew-product",
          showCheckbox: !AppUtils.isEqualString(this.state.annualProduct?.recId, '0'),
          checked: this.state.isAnnualAutoRenewSelected,
          onChange: () => {
            this.setState({
              isAnnualAutoRenewSelected: !this.state.isAnnualAutoRenewSelected
            }, () => {
              this.saveAutoRenewInfo({
                itemId: this.state.annualProduct?.itemId,
                recId: this.state.annualProduct?.recId,
                autoRenew: this.state.isAnnualAutoRenewSelected,
              });
            })
          },
        }}
      />
    );

  }
  render() {
    return <div ref={this.cRef}>{this.getMembershipCard()}</div>;
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    selectedPetId: customerProfileStore.selectedPetId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToShoppingCartItems: (item) => dispatch(addToShoppingCartItems(item)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingMembershipComponent);
