import React from "react";
import { NewPurchasePlanCard, ExistingMembershipPlanCard } from "../../../UI-Component-Library";
import ExistingMembershipComponent from "./existing-membership";
import { AppUtils } from "../../../utils/app-utils";
import { connect } from "react-redux";
import {
  changeCartTotal,
  addToShoppingCartItems,
  removeFromShoppingCartItems,
  changeSelectedPlan,
  toggleConsentCheckbox,
  setIsVetHelplineSelected,
  setIsPetMedAlertSelected,
  setIsDirectConnectSelected,
  setIsPetcademySelected
} from "../../../redux/reducers/membership-purchase-reducer";
import { MembershipPurchaseUtils } from "./membership-purchase.util";
import { toggleAutoRenewCheckbox } from "../../../redux/reducers/membership-purchase-reducer";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
import membership from "./membership";

class NewMembershipPurchaseComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedPetId: undefined,
      billingFrequency: 'yearly',
      ...MembershipPurchaseUtils.getDefaultData(),
    };
    this.cRef = React.createRef();
  }
 

  componentDidMount() {
    this.setState(
      {
        selectedPetId: this.props.selectedPetId,
      },
      this.mapParentPropsToState
    );
  }

  shouldDisablePurchasing() {
    return AppUtils.isEmptyString(
      this.props.animalFullDetails[this.props.selectedPetId]?.microchipNumber
    );
  }
  mapParentPropsToState() {
    const memberType = this.props.memberType;
    const isLPMPlusProduct = this.props.isLPMPlusProduct;
    const isPlanAlreadyPurchased = isLPMPlusProduct || memberType === 2 || memberType === 3;
    const billingFrequency = isPlanAlreadyPurchased ? "monthly" : "yearly";
    const membershipProducts = this.props.membershipProducts;
    const annualMembership = membershipProducts.annualMembership;
    const winbackProduct = membershipProducts.winBackBundle;
    const subscriptions = membershipProducts.subscriptions;
    const petMedAlertProduct = subscriptions?.subscriptionProducts.find(
      (item) => AppUtils.isEqualString(item.itemId, "PetMedAlert_Sub")
    );
    const vetHelplineProduct = subscriptions?.subscriptionProducts.find(
      (item) => AppUtils.isEqualString(item.itemId, "WhiskerDoc_Service")
    );
    const directConnectProduct = subscriptions?.subscriptionProducts.find(
      (item) => AppUtils.isEqualString(item.itemId, "DirectConnect")
    );
    const petcademyProduct = subscriptions?.subscriptionProducts.find(
      (item) => AppUtils.isEqualString(item.itemId, "Petcademy_Subption")
    );
    const lpmMembership = membershipProducts?.lpmMembership;
    if (lpmMembership) {
      lpmMembership.bundleList = MembershipPurchaseUtils.getFilteredBundleList(
        lpmMembership.bundleList
      );
    }

    const lpmPlusMembership = membershipProducts?.lpmPlusMembership;
    if (lpmPlusMembership) {
      lpmPlusMembership.bundleList = MembershipPurchaseUtils.getFilteredBundleList(
        lpmPlusMembership.bundleList
      );
    }


    this.setState({
      annualMembership,
      winbackProduct,
      lpmMembership,
      lpmPlusMembership,
      petMedAlertProduct,
      vetHelplineProduct,
      directConnectProduct,
      petcademyProduct,
      memberType,
      isLPMPlusProduct,
      isPlanAlreadyPurchased,
      billingFrequency
    });
  }
  changeSelectedPlan(plan) {
    let item;
    if (plan === "annual") {
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.lpmMembership?.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.lpmPlusMembership?.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.directConnectProduct?.itemId,
        true
      );
      this.changeDirectConnectSelected(false);

      this.props.changeSelectedPlan({
        petId: this.props.selectedPetId,
        selectedPlan: this.state.annualMembership.itemId,
      });
      item = {
        itemId: this.state.annualMembership.itemId,
        itemType: this.state.annualMembership.itemType,
        productName: AppUtils.getProductNameFromItemId(
          this.state.annualMembership.itemId
        ),
        productCost: AppUtils.getConvertedPrice(
          this.state.annualMembership.salesPrice
        ),
      };
      DataLayerUtils.addToCartEvent(item);
      this.props.addToShoppingCartItems({
        selectedPetId: this.props.selectedPetId,
        item,
      });
    }
    if (plan === "lpm") {
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.annualMembership?.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.petMedAlertProduct?.itemId,
        true
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.vetHelplineProduct?.itemId,
        true
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.directConnectProduct?.itemId,
        true
      );
      this.changeVetHelplineSelected(false);
      this.changePetMedAlertSelected(false);
      this.changeDirectConnectSelected(false);

      this.props.changeSelectedPlan({
        petId: this.props.selectedPetId,
        selectedPlan: this.state.lpmMembership.itemId,
      });
      item = {
        itemId: this.state.lpmMembership.itemId,
        itemType: this.state.lpmMembership.itemType,
        productName: AppUtils.getProductNameFromItemId(
          this.state.lpmMembership.itemId
        ),
        productCost: AppUtils.getConvertedPrice(
          this.state.lpmMembership.itemPrice
        ),
        bundleList: this.state.lpmMembership.bundleList,
      };
      DataLayerUtils.addToCartEvent(item);
      this.props.addToShoppingCartItems({
        selectedPetId: this.props.selectedPetId,
        item,
      });
    }
    if (plan === "lpmPlus") {
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.annualMembership?.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.petcademyProduct?.itemId,
        true
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.petMedAlertProduct?.itemId,
        true
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.vetHelplineProduct?.itemId,
        true
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.directConnectProduct?.itemId,
        true
      );
      this.changePetcademySelected(false);
      this.changeVetHelplineSelected(false);
      this.changePetMedAlertSelected(false);
      this.changeDirectConnectSelected(false);

      this.props.changeSelectedPlan({
        petId: this.props.selectedPetId,
        selectedPlan: this.state.lpmPlusMembership.itemId,
      });
      item = {
        itemId: this.state.lpmPlusMembership.itemId,
        itemType: this.state.lpmPlusMembership.itemType,
        productName: AppUtils.getProductNameFromItemId(
          this.state.lpmPlusMembership.itemId
        ),
        productCost: AppUtils.getConvertedPrice(
          this.state.lpmPlusMembership.itemPrice
        ),
        bundleList: this.state.lpmPlusMembership.bundleList,
      };
      DataLayerUtils.addToCartEvent(item);
      this.props.addToShoppingCartItems({
        selectedPetId: this.props.selectedPetId,
        item,
      });
    }
  }
  removeItemFromCart(petId, itemId, shouldSendId = false) {
    const indexes = MembershipPurchaseUtils.getPetIndexAndItemIndex(
      this.props.petShoppingCart,
      petId,
      itemId
    );
    if (indexes) {
      if (shouldSendId) {
        indexes['itemId'] = itemId;
      }
      this.props.removeFromShoppingCartItems(indexes);
    }
  }

  changePetMedAlertSelected(isSelected) {
    this.props.setIsPetMedAlertSelected({
      petId: this.props.selectedPetId,
      isPetMedAlertSelected: isSelected,
    });
  }
  changeVetHelplineSelected(isSelected) {
    this.props.setIsVetHelplineSelected({
      petId: this.props.selectedPetId,
      isVetHelplineSelected: isSelected,
    });
  }
  changeDirectConnectSelected(isSelected) {
    this.props.setIsDirectConnectSelected({
      petId: this.props.selectedPetId,
      isDirectConnectSelected: isSelected,
    });
  }
  changePetcademySelected(isSelected) {
    this.props.setIsPetcademySelected({
      petId: this.props.selectedPetId,
      isPetcademySelected: isSelected,
    });
  }
  toggleAdditionalProductSelection(productName) {
    let item;
    if (AppUtils.isEqualString(productName, "PetMedAlert")) {
      if (!this.props.isPetMedAlertSelectedMap[this.props.selectedPetId]) {
        item = {
          itemId: this.state.petMedAlertProduct.itemId,
          itemType: this.state.petMedAlertProduct.itemType,
          productName: this.state.petMedAlertProduct?.itemName,
          productCost: AppUtils.getConvertedPrice(
            this.state.petMedAlertProduct.price
          ),
        };
        DataLayerUtils.addToCartEvent(item);
        this.props.addToShoppingCartItems({
          selectedPetId: this.props.selectedPetId,
          item,
        });
      } else {
        this.removeItemFromCart(
          this.props.selectedPetId,
          this.state.petMedAlertProduct?.itemId
        );
      }
      this.changePetMedAlertSelected(
        !this.props.isPetMedAlertSelectedMap[this.props.selectedPetId]
      );
    }

    if (AppUtils.isEqualString(productName, "VetHelpLine")) {
      if (!this.props.isVetHelplineSelectedMap[this.props.selectedPetId]) {
        item = {
          itemId: this.state.vetHelplineProduct.itemId,
          itemType: this.state.vetHelplineProduct.itemType,
          productName:  this.state.vetHelplineProduct.itemName,
          productCost: AppUtils.getConvertedPrice(
            this.state.vetHelplineProduct.price
          ),
        };
        DataLayerUtils.addToCartEvent(item);
        this.props.addToShoppingCartItems({
          selectedPetId: this.props.selectedPetId,
          item,
        });
      } else {
        this.removeItemFromCart(
          this.props.selectedPetId,
          this.state.vetHelplineProduct?.itemId
        );
      }
      this.changeVetHelplineSelected(
        !this.props.isVetHelplineSelectedMap[this.props.selectedPetId]
      );
    }

    if (AppUtils.isEqualString(productName, "DirectConnect")) {
      if (!this.props.isDirectConnectSelectedMap[this.props.selectedPetId]) {
        item = {
          itemId: this.state.directConnectProduct.itemId,
          itemType: this.state.directConnectProduct.itemType,
          productName: this.state.directConnectProduct?.itemName,
          productCost: AppUtils.getConvertedPrice(
            this.state.directConnectProduct.price
          ),
        };
        DataLayerUtils.addToCartEvent(item);
        this.props.addToShoppingCartItems({
          selectedPetId: this.props.selectedPetId,
          item,
        });
      } else {
        this.removeItemFromCart(
          this.props.selectedPetId,
          this.state.directConnectProduct?.itemId
        );
      }
      this.changeDirectConnectSelected(
        !this.props.isDirectConnectSelectedMap[this.props.selectedPetId]
      );
    }

    if (AppUtils.isEqualString(productName, "Petcademy")) {
      if (!this.props.isPetcademySelectedMap[this.props.selectedPetId]) {
        item = {
          itemId: this.state.petcademyProduct.itemId,
          itemType: this.state.petcademyProduct.itemType,
          productName:  this.state.petcademyProduct.itemName,
          productCost: AppUtils.getConvertedPrice(
            this.state.petcademyProduct.price
          ),
        };
        DataLayerUtils.addToCartEvent(item);
        this.props.addToShoppingCartItems({
          selectedPetId: this.props.selectedPetId,
          item,
        });
      } else {
        this.removeItemFromCart(
          this.props.selectedPetId,
          this.state.petcademyProduct?.itemId
        );
      }
      this.changePetcademySelected(
        !this.props.isPetcademySelectedMap[this.props.selectedPetId]
      );
    }
  }
  isAnnualSelected() {
    return this.state.annualMembership
      ? AppUtils.isEqualString(
        this.props.selectedPlans[this.props.selectedPetId],
        this.state.annualMembership.itemId
      )
      : false;
  }

  isLpmPlanSelected() {
    return this.state.lpmMembership
      ? AppUtils.isEqualString(
          this.props.selectedPlans[this.props.selectedPetId],
          this.state.lpmMembership.itemId
        )
      : false;
  }

  isLpmPlusPlanSelected() {
    return this.state.lpmPlusMembership
      ? AppUtils.isEqualString(
        this.props.selectedPlans[this.props.selectedPetId],
        this.state.lpmPlusMembership.itemId
      )
      : false;
  }

  handleProductTypeChange = (event) => {
    this.setState({
      billingFrequency: event,
    });
  }

  shouldDisableDirectConnect() {
    if (this.isLpmPlanSelected() || this.isLpmPlusPlanSelected() || this.state.directConnectProduct?.isIncludedInPlan) {
      return {
        disable: true,
        label: 'Included in your plan'
      }
    }

    if (this.isAnnualSelected()) {
      return {
        disable: true,
        label: 'Add to Cart'
      }
    }

    if (this.state.directConnectProduct?.isSubscribed) {
      return {
        disable: true,
        label: 'Subscribed'
      }
    }

    if (this.props.isDirectConnectSelectedMap[this.props.selectedPetId]) {
      return {
        disable: false,
        label: 'Added'
      }
    }

    return {
      disable: false,
      label: 'Add to Cart'
    }
  }

  shouldDisableVetHelpline() {
    if (this.isLpmPlanSelected() || this.isLpmPlusPlanSelected()) {
      return {
        disable: true,
        label: '1 year included'
      }
    }

    if (this.state.vetHelplineProduct?.isSubscribed) {
      return {
        disable: true,
        label: 'Subscribed'
      }
    }

    if (this.props.isVetHelplineSelectedMap[this.props.selectedPetId]) {
      return {
        disable: false,
        label: 'Added'
      }
    }

    return {
      disable: false,
      label: 'Add to Cart'
    }
  }

  shouldDisablePetcademy() {
    if (this.isLpmPlusPlanSelected() || this.state.petcademyProduct?.isIncludedInPlan) {
      return {
        disable: true,
        label: 'Included in your plan'
      }
    }

    if (this.state.petcademyProduct?.isSubscribed) {
      return {
        disable: true,
        label: 'Subscribed'
      }
    }

    if (this.props.isPetcademySelectedMap[this.props.selectedPetId]) {
      return {
        disable: false,
        label: 'Added'
      }
    }

    return {
      disable: false,
      label: 'Add to Cart'
    }
  }

  shouldDisablePetMetAlert() {
    if (this.isLpmPlanSelected() || this.isLpmPlusPlanSelected()) {
      return {
        disable: true,
        label: '1 year included'
      }
    }

    if (this.state.petMedAlertProduct?.isSubscribed) {
      return {
        disable: true,
        label: 'Subscribed'
      }
    }

    if (this.props.isPetMedAlertSelectedMap[this.props.selectedPetId]) {
      return {
        disable: false,
        label: 'Added'
      }
    }

    return {
      disable: false,
      label: 'Add to Cart'
    }
  }

  shouldDisableAnnual() {
    if (this.isAnnualSelected()) {
      return {
        disable: false,
        label: 'Added'
      }
    }
    if (this.props.isDirectConnectSelectedMap[this.props.selectedPetId]) {
      return {
        disable: true,
        label: 'Add to Cart'
      }
    }
    return {
      disable: false,
      label: 'Add to Cart'
    }
  }

  shouldDisableLpm() {
    if (this.state.memberType === 2 || this.state.memberType === 3) {
      if (!this.state.isLPMPlusProduct) {
        return {
          disable: true,
          label: 'Your Plan'
        }
      }
    }

    if (this.isLpmPlanSelected()) {
      return {
        disable: false,
        label: 'Added'
      }
    }

    if (this.isLpmPlusPlanSelected()) {
      return {
        disable: true,
        label: 'Add to Cart'
      }
    }

    return {
      disable: false,
      label: 'Add to Cart'
    }
  }

  shouldDisableLpmPlus() {
    if (this.isLpmPlusPlanSelected()) {
      return {
        disable: false,
        label: 'Added'
      }
    }

    if (this.isLpmPlanSelected()) {
      return {
        disable: true,
        label: 'Add to Cart'
      }
    }

    if (this.state.memberType === 2 || this.state.memberType === 3) {
      if (!this.state.isLPMPlusProduct) {
        return {
          disable: false,
          label: 'Upgrade'
        }
      }
    }

    return {
      disable: false,
      label: 'Add to Cart'
    }
  }

  shouldDisplayMembershipSection() {
    if (
      !this.state.directConnectProduct?.isDisplayed &&
      !this.state.vetHelplineProduct?.isDisplayed &&
      !this.state.petcademyProduct?.isDisplayed &&
      !this.state.petMedAlertProduct?.isDisplayed &&
      this.state.isPlanAlreadyPurchased
    ) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <React.Fragment>
        <ExistingMembershipComponent
          memberType={this.state.memberType}
          isLPMPlusProduct={this.state.isLPMPlusProduct}
          winbackBundle={this.state.winbackProduct}
        />
        <div ref={this.cRef}>
          {this.shouldDisplayMembershipSection() && (<NewPurchasePlanCard
            isAlreadyPurchased={this.state.isPlanAlreadyPurchased}
            productTypeProps={{
              label: "",
              name: "billingFrequency",
              value: this.state.billingFrequency,
              items: [
                {
                  value: "yearly",
                  label: "One-Time Purchase",
                  buttonId: "productTypeId1"
                },
                {
                  value: "monthly",
                  label: "Subscriptions",
                  buttonId: "productTypeId2"
                },
              ],
              onChange: (event) => {
                this.handleProductTypeChange(event);
              },
            }}
            lifeTimeProtectionOptionProps={{
              mostPopular: this.state.lpmMembership?.isMostPopular || false,
              productName: 'Lifetime Protection',
              description: 'Lifetime protection for your pet for a one time fee',
              price: AppUtils.getConvertedPrice(
                this.state.lpmMembership?.itemPrice
              ),
              frequency: 'One-Time Fee',
              label: this.shouldDisableLpm()?.label,
              shouldDisable: this.shouldDisableLpm()?.disable,
              onClick: () => {
                if (this.isLpmPlanSelected()) {
                  this.props.changeSelectedPlan({
                    petId: this.props.selectedPetId,
                    selectedPlan: undefined,
                  });
                  this.removeItemFromCart(
                    this.props.selectedPetId,
                    this.state.lpmMembership?.itemId
                  );
                } else {
                  this.changeSelectedPlan("lpm");
                }
              }
            }}
            lifeTimeProtectionPlusOptionProps={{
              mostPopular: this.state.lpmPlusMembership?.isMostPopular || false,
              productName: 'Lifetime Protection Plus',
              description: 'Lifetime protection for your pet for a one time fee with exclusive extras',
              price: AppUtils.getConvertedPrice(
                this.state.lpmPlusMembership?.itemPrice
              ),
              frequency: 'One-Time Fee',
              label: this.shouldDisableLpmPlus()?.label,
              shouldDisable: this.shouldDisableLpmPlus()?.disable,
              onClick: () => {
                if (this.isLpmPlusPlanSelected()) {
                  this.props.changeSelectedPlan({
                    petId: this.props.selectedPetId,
                    selectedPlan: undefined,
                  });
                  this.removeItemFromCart(
                    this.props.selectedPetId,
                    this.state.lpmPlusMembership?.itemId
                  );
                } else {
                  this.changeSelectedPlan("lpmPlus");
                }
              }
            }}
            annualProtectionOptionProps={{
              productName: 'Annual Protection Membership',
              shouldDisplay: (
                !!this.state.annualMembership &&
                AppUtils.isUsCountry() &&
                !this.isLpmPlanSelected() &&
                !this.isLpmPlusPlanSelected()
              ),
              description: 'Annual protection for your pet for a low yearly fee',
              price: AppUtils.getConvertedPrice(
                this.state.annualMembership?.salesPrice
              ),
              frequency: 'for the first year',
              label2: `$${this.state.annualMembership?.renewalPrice} per year thereafter`,
              label: this.shouldDisableAnnual()?.label,
              shouldDisable: this.shouldDisableAnnual()?.disable,
              onClick: () => {
                if (this.isAnnualSelected()) {
                  this.props.changeSelectedPlan({
                    petId: this.props.selectedPetId,
                    selectedPlan: undefined,
                  });
                  this.removeItemFromCart(
                    this.props.selectedPetId,
                    this.state.annualMembership?.itemId
                  );
                } else {
                  this.changeSelectedPlan("annual");
                }
              }
            }}
            directConnectOptionProps={{
              productName: this.state.directConnectProduct?.itemName || 'DirectConnect',
              shouldDisplay: this.state.directConnectProduct?.isDisplayed,
              description: "Connect with your pet’s finder to arrange a quick & safe reunion",
              price: AppUtils.getConvertedPrice(
                this.state.directConnectProduct?.price
              ),
              frequency: 'Per Month',
              active: false,
              label: this.shouldDisableDirectConnect()?.label,
              shouldDisable: this.shouldDisableDirectConnect()?.disable,
              onClick: () => {
                this.toggleAdditionalProductSelection("DirectConnect");
              }
            }}
            vetHelplineOptionProps={{
              productName: this.state.vetHelplineProduct?.itemName || '24/7 Vet Helpline',
              shouldDisplay: this.state.vetHelplineProduct?.isDisplayed,
              description: "Unlimited access to veterinary professionals by phone, email or live chat (provided by whiskerDocs)",
              price: AppUtils.getConvertedPrice(
                this.state.vetHelplineProduct?.price,
              ),
              frequency: 'Per Month',
              active: false,
              label: this.shouldDisableVetHelpline()?.label,
              shouldDisable: this.shouldDisableVetHelpline()?.disable,
              onClick: () => {
                this.toggleAdditionalProductSelection("VetHelpLine");
              }
            }}
            petcademyOptionProps={{
              productName: this.state.petcademyProduct?.itemName || 'Petcademy',
              shouldDisplay: this.state.petcademyProduct?.isDisplayed,
              description: "Online platform offering customized training plans, behavioral support, & video lessons for new pet parents",
              price: AppUtils.getConvertedPrice(
                this.state.petcademyProduct?.price
              ),
              frequency: 'Per Month',
              active: false,
              label: this.shouldDisablePetcademy()?.label,
              shouldDisable: this.shouldDisablePetcademy()?.disable,
              onClick: () => {
                this.toggleAdditionalProductSelection("Petcademy");
              }
            }}
            petMedAlertOptionProps={{
              productName: this.state.petMedAlertProduct?.itemName || '24PetMedAlert®',
              shouldDisplay: this.state.petMedAlertProduct?.isDisplayed,
              description: "Protect your pet by relaying their critical medical & behavioral information to a shelter, rescue, or vet if your pet is lost.",
              price: AppUtils.getConvertedPrice(
                this.state.petMedAlertProduct?.price
              ),
              frequency: 'Per Month',
              active: false,
              label: this.shouldDisablePetMetAlert()?.label,
              shouldDisable: this.shouldDisablePetMetAlert()?.disable,
              onClick: () => {
                this.toggleAdditionalProductSelection("PetMedAlert");
              }
            }}
          />)}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  const membershipPurchaseStore = state.membershipPurchaseStore;
  return {
    selectedPlans: membershipPurchaseStore.selectedPlans,
    isVetHelplineSelectedMap: membershipPurchaseStore.isVetHelplineSelectedMap,
    isPetMedAlertSelectedMap: membershipPurchaseStore.isPetMedAlertSelectedMap,
    isDirectConnectSelectedMap: membershipPurchaseStore.isDirectConnectSelectedMap,
    isPetcademySelectedMap: membershipPurchaseStore.isPetcademySelectedMap,
    autoRenewCheckboxes: membershipPurchaseStore.autoRenewCheckboxes,
    consentCheckboxes: membershipPurchaseStore.consentCheckboxes,
    petShoppingCart: membershipPurchaseStore.petShoppingCart,
    selectedAnimal: customerProfileStore.selectedAnimal,
    selectedPetId: customerProfileStore.selectedPetId,
    animalFullDetails: customerProfileStore.animalFullDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeCartTotal: (price) => dispatch(changeCartTotal(price)),
    changeSelectedPlan: (plan) => dispatch(changeSelectedPlan(plan)),
    addToShoppingCartItems: (item) => dispatch(addToShoppingCartItems(item)),
    removeFromShoppingCartItems: (data) =>
      dispatch(removeFromShoppingCartItems(data)),
    toggleAutoRenewCheckbox: (selectedPetId) =>
      dispatch(toggleAutoRenewCheckbox(selectedPetId)),
    toggleConsentCheckbox: (selectedPetId) =>
      dispatch(toggleConsentCheckbox(selectedPetId)),
    setIsVetHelplineSelected: (data) =>
      dispatch(setIsVetHelplineSelected(data)),
    setIsPetMedAlertSelected: (data) =>
      dispatch(setIsPetMedAlertSelected(data)),
    setIsDirectConnectSelected: (data) =>
      dispatch(setIsDirectConnectSelected(data)),
    setIsPetcademySelected: (data) =>
      dispatch(setIsPetcademySelected(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewMembershipPurchaseComponent);
