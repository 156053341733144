import React from "react";
import { AppUtils } from "../../../utils/app-utils";
import MembershipPurchaseComponent from "./membership-purchase";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { connect } from "react-redux";
import LpmMembershipComponent from "./lpm-membership";
import LpmPlusMembershipComponent from "./lpm-plus-membership";
import AnnualMembership from "./annual-membership";
import TagPurchaseComponent from "./tag-purchase";
import WinbackOfferComponent from "./winback-offer";
import { PageContent } from "../../../UI-Component-Library";
import { MembershipPurchaseUtils } from "./membership-purchase.util";
import { changeWinbackOfferAvailability, removeFromShoppingCartItems, } from "../../../redux/reducers/membership-purchase-reducer";
import ByteTagPurchase from "./byte-tag-purchase";
import NewMembershipPurchaseComponent from "./new-membership-purchase";
class Membership extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedPetId: undefined,
      membershipProducts: undefined,
      isLPMPlusProduct: 0,
      showNewMembershipDesign: false,
      speciesId: undefined,
    };
    this.cRef = React.createRef();
  }
  componentDidUpdate() {
    if (
      this.props.selectedPetId &&
      this.props.selectedPetId !== this.state.selectedPetId
    ) {
      AppUtils.triggerAdobeTargetView('membershipPurchase');

      this.setState(
        {
          selectedPetId: this.props.selectedPetId,
          membershipProducts: undefined,
          memberType: undefined,
          speciesId: undefined,
          isLPMPlusProduct: 0,
          showNewMembershipDesign: false,
        },
        this.loadAvailableMembershipProducts
      );
    }
  }
  componentDidMount() {
    // Trigger target view for membership purchase.
    AppUtils.triggerAdobeTargetView('membershipPurchase');

    this.setState(
      {
        selectedPetId: this.props.selectedPetId,
        membershipProducts: undefined,
        memberType: undefined,
        speciesId: undefined,
        isLPMPlusProduct: 0,
        showNewMembershipDesign: false,
      },
      this.loadAvailableMembershipProducts
    );
  }
  loadAvailableMembershipProducts() {
    if (
      !this.props.selectedPetId ||
      AppUtils.isEmptyString(this.props.selectedPetId)
    ) {
      return;
    }
    axios
      .processPost(ApiConstants.URL_LOAD_AVAILABLE_MEMBERSHIP, this.cRef, {
        busRelAccount: AppUtils.getBusRelAccount(),
        animalId: this.props.selectedPetId,
        country: AppUtils.getCountryCode(),
      })
      .then((response) => {
        const data = response.data;
        this.syncWinbackOfferAvailability(data.membershipProducts?.winBackBundle);
        this.setState({
          isLPMPlusProduct: data.isLPMPlusProduct,
          showNewMembershipDesign: data.showSubscriptionProducts,
          memberType: data.memberType,
          membershipProducts: data.membershipProducts,
          speciesId: data.speciesId,
        });
      });
  }

  isAnnualMember(memberType) {
    return memberType === 1;
  }

  hadBothProducts() {
    return (
      this.state.membershipProducts?.annualMembership &&
      this.state.membershipProducts?.lpmMembership
    );
  }

  syncWinbackOfferAvailability(data){
    const isAvailable = (data)? true : false;
    if(!isAvailable){
      const indexes = MembershipPurchaseUtils.getPetIndexAndItemType(
        this.props.petShoppingCart,
        this.props.selectedPetId,
        "WINBACK Product"
      );
      if(indexes){
        this.props.removeFromShoppingCartItems(indexes);
      }
    }
    this.props.changeWinbackOfferAvailability({
      petId: this.props.selectedPetId,
      isAvailable,
    })
  }

  getScreenBasedOnType(memberType) {
    if (memberType === undefined) {
      return <div></div>;
    }

    if (this.state.showNewMembershipDesign) {
      return (
        <NewMembershipPurchaseComponent
          memberType={this.state.memberType}
          isLPMPlusProduct={this.state.isLPMPlusProduct}
          membershipProducts={this.state.membershipProducts}
        ></NewMembershipPurchaseComponent>
      );
    }

    if(this.state.membershipProducts?.winBackBundle){
      return (
        <WinbackOfferComponent
          membershipProducts={this.state.membershipProducts?.winBackBundle}
        />
      )
    }

    if (this.state.isLPMPlusProduct) {
      return (
        <LpmPlusMembershipComponent></LpmPlusMembershipComponent>
      )
    }
    if (memberType === 1 || memberType === 5) {
      return (
        <AnnualMembership
          lpmMembership={this.state.membershipProducts?.lpmMembership}
        ></AnnualMembership>
      );
    }
    if (memberType === 0 || memberType === 4) {
      if (this.hadBothProducts()) {
        return (
          <MembershipPurchaseComponent
            membershipProducts={this.state.membershipProducts}
          ></MembershipPurchaseComponent>
        );
      } else {
        return (
          <AnnualMembership
            lpmMembership={this.state.membershipProducts?.lpmMembership}
          ></AnnualMembership>
        );
      }
    }
    return (
      <LpmMembershipComponent
        memberType={this.state.memberType}
      ></LpmMembershipComponent>
    );
  }

  getTagScreen() {
    if (AppUtils.isUsCountry() && AppUtils.isPethealthProgram()) {
      return (
        <ByteTagPurchase
          membershipProducts={this.state.membershipProducts}
          speciesId={this.state.speciesId}
        ></ByteTagPurchase>
      );
    } else {
      return (
        <TagPurchaseComponent
          membershipProducts={this.state.membershipProducts}
        ></TagPurchaseComponent>
      );
    }
  }

  render() {
    return (
      <div ref={this.cRef} id={this.props.id}>
        {this.getScreenBasedOnType(this.state.memberType)}
        {this.state.memberType !== undefined && (
          <PageContent>
            {this.getTagScreen()}
          </PageContent>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  const membershipPurchaseStore = state.membershipPurchaseStore;
  return {
    selectedPetId: customerProfileStore.selectedPetId,
    petShoppingCart: membershipPurchaseStore.petShoppingCart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeWinbackOfferAvailability: (data) => dispatch(changeWinbackOfferAvailability(data)),
    removeFromShoppingCartItems: (data) =>
      dispatch(removeFromShoppingCartItems(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Membership);
