import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { format } from 'date-fns'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Divider,
  Link,
  Icon, 
  Text,
  Checkbox,
  Button
} from '../../'
import { OneTimePurchase, SubsPetcademy, SubsVetHelpline } from '../SvgIcons';
import { PUBLIC_URL } from "../../constants";

export class ExistingMembershipPlanCard extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      readMore: false,
    }

    this.readMoreHandler = this.readMoreHandler.bind(this);
  }

  static ProductLogos = Object.freeze({
    petcademy: <SubsPetcademy />,
    vetHelp: <SubsVetHelpline />
  });

  static propTypes = {
    membershipProduct : PropTypes.shape({
      logo: PropTypes.element,
      productName: PropTypes.string,
      productBenefits: PropTypes.array,

      id: PropTypes.string,
      className: PropTypes.string,
      style: PropTypes.object,
      refProp: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ])
    }),
    subscriptionProducts: PropTypes.arrayOf(
      PropTypes.shape({
        logo: PropTypes.element,
        productName: PropTypes.string,
        price: PropTypes.string,
        frequency: PropTypes.string,
        date: PropTypes.string,
        expiryDate: PropTypes.string,
        isCancelled: PropTypes.bool,

        id: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        refProp: PropTypes.oneOfType([
          PropTypes.func,
          PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
      })
    ),
    combinedMedVetProduct: PropTypes.shape({
      logo: PropTypes.element,
      productName: PropTypes.string,
      price: PropTypes.string,
      frequency: PropTypes.string,
      date: PropTypes.string,
      expiryDate: PropTypes.string,
      autoRenew: PropTypes.bool,

      id: PropTypes.string,
      className: PropTypes.string,
      style: PropTypes.object,
      refProp: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ])
    }),
    annualProduct: PropTypes.shape({
      logo: PropTypes.element,
      productName: PropTypes.string,
      price: PropTypes.string,
      frequency: PropTypes.string,
      date: PropTypes.string,
      expiryDate: PropTypes.string,
      autoRenew: PropTypes.bool,
      recId: PropTypes.string,
      itemId: PropTypes.string,

      id: PropTypes.string,
      className: PropTypes.string,
      style: PropTypes.object,
      refProp: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ])
    }),
    winbackProduct: PropTypes.shape({
      expiryDate: PropTypes.string,
      offerPrice: PropTypes.string,
    }),
    autoRenewCheckboxProps: PropTypes.shape({
      showCheckbox: PropTypes.bool,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),
    annualAutoRenewCheckboxProps: PropTypes.shape({
      showCheckbox: PropTypes.bool,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),
    renewButtonProps: PropTypes.shape({
      active: PropTypes.bool,
      onClick: PropTypes.func,
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    subscriptionProducts: [{
      productName: '24/7 Vet Helpline',
      price: '$4.95',
      frequency: 'per month',
      date: '07-06-2025'
    }],
    membershipProduct: {
      productName: 'Lifetime Protection Membership',
      productBenefits: [
        "Access to Lost Pet Recovery Specialists who can quickly launch the process of finding your pet",
        "With 24PetMedAlert®, critical medical and behavioral information will be shared with the shelter or vet the pet is brought to when found (complimentary for 1 year)",
        "Reach veterinary professionals anytime by phone, email or live chat powered by whiskerDocs® (complimentary for 1 year)",
        "Connect with your pet's finder and arrange a quick, safe reunion using DirectConnect.",
        "Save on pet-sitting and dog walking services with a $30 Rover coupon.",
        "Receive a $25 Petco coupon, redeemable for in-store purchases."
      ]
    },
  }

  readMoreHandler() {
    this.setState({
      readMore: !this.state.readMore
    })
  }

  render() {
    const {
      subscriptionProducts,
      membershipProduct,
      annualProduct,
      combinedMedVetProduct,
      winbackProduct,
      autoRenewCheckboxProps,
      annualAutoRenewCheckboxProps,
      renewButtonProps,
      id,
      className,
      style,
      refProp
    } = this.props

    const isMembershipProductAvailable = Object.keys(membershipProduct).length != 0;

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn('ui-existing-membership-plan-card', className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Your Membership</PaperHeadline>

        <PaperContent>
          {isMembershipProductAvailable && (<div className='ui-existing-plan-card'>
            <Text className='ui-existing-plan-card__membership'>
              {membershipProduct.logo ? membershipProduct.logo : <OneTimePurchase />}
              {membershipProduct.productName}
            </Text>

            <Text className='ui-existing-plan-card__label'>
              Included in your plan
            </Text>
            <Divider />
            
            <div>
              <div>
                {membershipProduct.productBenefits.map((item, index) => {
                  if (this.state.readMore || index <= 2) {
                    return (<div className="ui-existing-plan-card__benefits" key={`${membershipProduct.productName.replaceAll(' ', '-')}-Benefits-${index}`}>
                      <Icon type='done' />
                      <span>{item}</span>
                    </div>)
                  }
                })}
              </div>
              {(membershipProduct.productBenefits.length > 3) && (<div className="ui-existing-plan-card__benefits" onClick={() => this.readMoreHandler()}>
                {!this.state.readMore ? <Icon type='expand_more' /> : <Icon type='expand_less' />}
                <span className='ui-existing-plan-card__expand'>{!this.state.readMore ? 'View all benefits' : 'Show less'}</span>
              </div>
              )}
            </div>
          </div>)}

          {!!annualProduct && (
            <div className='ui-existing-plan-card'>
              <Text className='ui-existing-plan-card__membership'>
                {annualProduct.logo ? annualProduct.logo : <OneTimePurchase />}
                {annualProduct.productName}
              </Text>

              <div className='ui-existing-plan-card__price'>
                  <Text className='ui-existing-plan-card__price-value'>
                    {annualProduct?.price.split('.')[0]}
                    {annualProduct?.price.split('.')?.[1] && (<sup>.{annualProduct?.price.split('.')[1]}</sup>)}</Text>
                  <span>
                    {/* <Text className='ui-existing-plan-card__currency'>USD</Text> */}
                    <Text className='ui-existing-plan-card__frequency'>{annualProduct?.frequency}</Text>
                  </span>
              </div>
              {annualAutoRenewCheckboxProps.showCheckbox && (<>
                <Text className='ui-existing-plan-card__label'>
                  Renewal Details
                </Text>
                <Divider />
                <div className='ui-existing-plan-card__renewal-details'>
                  {annualAutoRenewCheckboxProps.checked && (
                    <>
                      <Text className='ui-existing-plan-card__next'>Next Payment Due: {format(new Date(annualProduct?.date), 'MMMM do, yyyy')}</Text>
                      <Text className='ui-existing-plan-card__detail'>
                        On {format(new Date(annualProduct?.date), 'MMMM do, yyyy')} you will be charged {annualProduct?.price} for the subscription
                      </Text>
                    </>
                  )}
                  {!annualAutoRenewCheckboxProps.checked && (
                    <>
                      <Text className='ui-existing-plan-card__end'>Coverage Ends: {format(new Date(annualProduct?.expiryDate), 'MMMM do, yyyy')}</Text>
                    </>
                  )}
                  <Checkbox
                    className='ui-existing-membership-plan__checkbox'
                    label={
                      <>
                        To continue accessing your membership benefits beyond your first year,
                        click here to opt-in to auto-renew these services for only {annualProduct?.price}/year.
                      </>
                    }
                    {...annualAutoRenewCheckboxProps}
                  />
                </div>
              </>)}
            </div>
          )}

          {/* winback Product */}
          {!!winbackProduct && (<div className='ui-existing-plan-card'>
            <div className='ui-existing-plan-card__winback'>
              <div className='ui-existing-plan-card__winback-content'>
                <Text type={Text.Types.headline}>Save money, time and stress!</Text>
                <Text type={Text.Types.body}>
                  Save 25% on these additional coverages. Only {winbackProduct.offerPrice} (reg. $19.95) for 1 full year of access to both 24PetMedAlert® and 24/7 Vet Helpline.
                  Offer ends {winbackProduct.expiryDate}
                </Text>
                <Button
                  size={Button.Sizes.small}
                  color={Button.Colors.tertiary}
                  {...renewButtonProps}
                >
                  Renew Now
                </Button>
              </div>
              <div>
                <img
                  // className={cn("ui-byte-tags-card__tag-image")}
                  // alt={`${tagShape}-${tagStyle}`}
                  src={PUBLIC_URL + "WinbackImage.png"}
                />

              </div>
            </div>
            <Text className='ui-existing-plan-card__label'>
              Included Coverage
              <Text className='ui-existing-plan-card__detail'>
                1 year included with renewal
              </Text>
            </Text>
            <Divider />
            <div>
              <Text className='ui-existing-plan-card__membership'>
                {ExistingMembershipPlanCard.ProductLogos['vetHelp']}
                24/7 Vet Helpline
              </Text>
              <div className={cn('ui-existing-plan-card__benefits', 'ui-existing-plan-card__winback-product')}>
                <Icon type='done' />
                <span>Reach veterinary professionals anytime by phone, email or live chat powered by whiskerDocs® </span>
              </div>
            </div>
            <div>
              <Text className='ui-existing-plan-card__membership'>
                {<OneTimePurchase />}
                24PetMedAlert®
              </Text>
              <div className={cn('ui-existing-plan-card__benefits', 'ui-existing-plan-card__winback-product')}>
                <Icon type='done' />
                <span>With 24PetMedAlert®, critical medical and behavioral information will be shared with the shelter or vet the pet is brought to when found</span>
              </div>
            </div>
          </div>)}

          {/* subscriptionProducts  */}
          {(!!combinedMedVetProduct && !winbackProduct) && (
            <div className='ui-existing-plan-card'>
              <Text className='ui-existing-plan-card__membership'>
                <OneTimePurchase />
                24PetMedAlert®
                {ExistingMembershipPlanCard.ProductLogos['vetHelp']}
                24/7 Vet Helpline
              </Text>

              <div className='ui-existing-plan-card__price'>
                  <Text className='ui-existing-plan-card__price-value'>
                    {combinedMedVetProduct?.price.split('.')[0]}
                    {combinedMedVetProduct?.price.split('.')?.[1] && (<sup>.{combinedMedVetProduct?.price.split('.')[1]}</sup>)}</Text>
                  <span>
                    {/* <Text className='ui-existing-plan-card__currency'>USD</Text> */}
                    <Text className='ui-existing-plan-card__frequency'>{combinedMedVetProduct?.frequency}</Text>
                  </span>
              </div>
              <Text className='ui-existing-plan-card__label'>
                Renewal Details
                <Text className='ui-existing-plan-card__detail'>
                  1 year included with your Lifetime Protection Membership
                </Text>
              </Text>
              {autoRenewCheckboxProps.showCheckbox && (<>
                <Divider />
                <div className='ui-existing-plan-card__renewal-details'>
                  {autoRenewCheckboxProps.checked && (
                    <>
                      <Text className='ui-existing-plan-card__next'>Next Payment Due: {format(new Date(combinedMedVetProduct?.date), 'MMMM do, yyyy')}</Text>
                      <Text className='ui-existing-plan-card__detail'>
                        On {format(new Date(combinedMedVetProduct?.date), 'MMMM do, yyyy')} you will be charged {combinedMedVetProduct?.price} for the subscription
                      </Text>
                    </>
                  )}
                  {!autoRenewCheckboxProps.checked && (
                    <>
                      <Text className='ui-existing-plan-card__end'>Coverage Ends: {format(new Date(combinedMedVetProduct?.expiryDate), 'MMMM do, yyyy')}</Text>
                    </>
                  )}
                  <Checkbox
                    className='ui-existing-membership-plan__checkbox'
                    label={
                      <>
                        To continue accessing 24PetMedAlert® and 24/7 Vet Helpline beyond your complimentary first year,
                        click here to opt-in to auto-renew these services for only {combinedMedVetProduct?.price}/year.
                      </>
                    }
                    {...autoRenewCheckboxProps}
                  />
                </div>
              </>)}
            </div>
          )}
          {subscriptionProducts.map((item, index) => {
            return (
              <div className='ui-existing-plan-card' key={`${item.productName?.replaceAll(' ', '-')}-${index}`}>
                <Text className='ui-existing-plan-card__membership'>
                  {item.logo ? ExistingMembershipPlanCard.ProductLogos[item.logo] : <OneTimePurchase />}
                  {item.productName}
                </Text>

                <div className='ui-existing-plan-card__price'>
                  <Text className='ui-existing-plan-card__price-value'>
                    {item?.price.split('.')[0]}
                    {item?.price.split('.')?.[1] && (<sup>.{item?.price.split('.')[1]}</sup>)}</Text>
                  <span>
                    {/* <Text className='ui-existing-plan-card__currency'>USD</Text> */}
                    <Text className='ui-existing-plan-card__frequency'>{item.frequency}</Text>
                  </span>
                </div>
                <Text className='ui-existing-plan-card__label'>
                  Renewal Details
                </Text>
                <Divider />
                <div className='ui-existing-plan-card__renewal-details'>
                  {!item?.isCancelled && (
                    <>
                      <Text className='ui-existing-plan-card__next'>Next Payment Due: {format(new Date(item.date), 'MMMM do, yyyy')}</Text>
                      <Text className='ui-existing-plan-card__detail'>
                        On {format(new Date(item.date), 'MMMM do, yyyy')} you will be charged {item.price} for the subscription
                      </Text>
                    </>
                  )}
                  {item?.isCancelled && (
                    <>
                      <Text className='ui-existing-plan-card__end'>Coverage Ends: {format(new Date(item?.expiryDate), 'MMMM do, yyyy')}</Text>
                      <Text className='ui-existing-plan-card__detail'>
                        Your subscription has been cancelled and will end on {format(new Date(item?.expiryDate), 'MMMM do, yyyy')}
                      </Text>
                    </>
                  )}
                  <Link color={Link.Colors.secondary}>Call 1-866-597-2424 to update your plan</Link>
                </div>
              </div>
            )
          })}
        </PaperContent>
      </Paper>
    )
  }
}
