import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Paper,
  PaperHeadline,
  Text,
  PaperContent,
  Option,
  Divider,
  Icon
} from '../..';
import { OneTimePurchase } from '../SvgIcons';
import classNames from 'classnames';

export class ProductCard extends Component {
  constructor() {
    super();
    this.state = {
      readMore: false,
    }

    this.readMoreHandler = this.readMoreHandler.bind(this);
  }

  static propTypes = {
    shouldDisable: PropTypes.bool,
    logo: PropTypes.element,
    productName: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.string,
    frequency: PropTypes.string,
    selectPlanProps: PropTypes.func,
    productBenefits: PropTypes.array,
    mostPopular: PropTypes.bool,
    optionLabel: PropTypes.any,
    label2: PropTypes.string,
    annualClass: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    mostPopular: false,
    productName: 'Lifetime Protection',
    description: 'Lifetime protection for your pet for a one time fee',
    price: '$99.99',
    frequency: 'one-time fee',
    optionLabel: 'Select Plan',
    annualClass: '',
    productBenefits: [
      "Access to Lost Pet Recovery Specialists who can quickly launch the process of finding your pet",
      "With 24PetMedAlert®, critical medical and behavioral information will be shared with the shelter or vet the pet is brought to when found (complimentary for 1 year)",
      "Reach veterinary professionals anytime by phone, email or live chat powered by whiskerDocs® (complimentary for 1 year)",
      "Connect with your pet's finder and arrange a quick, safe reunion using DirectConnect.",
      "Save on pet-sitting and dog walking services with a $30 Rover coupon.",
      "Receive a $25 Petco coupon, redeemable for in-store purchases."
    ]
  }


  readMoreHandler() {
    this.setState({
      readMore: !this.state.readMore
    })
  }

  render() {
    const {
      shouldDisable,
      logo,
      productName,
      description,
      price,
      frequency,
      selectPlanProps,
      productBenefits,
      mostPopular,
      optionLabel,
      label2,
      annualClass,
      id,
      className,
      style,
      refProp
    } = this.props;

    const modifiedPrice = price.split('.');
    const isLpmPlus = productName === 'Lifetime Protection Plus';
    return (
      <Paper
        className={cn('ui-product-card', annualClass, className )}
      >
        {mostPopular && (<PaperHeadline className='ui-product-card__popular'>
          <div>
            <Icon type='star' />
            <span>Most Popular</span>
          </div>
        </PaperHeadline>)}
        <PaperContent>
          <Text className='ui-product-card__label'>
            {logo ? logo : <OneTimePurchase />}
            {productName}
          </Text>
          <Text className='ui-product-card__description'>{description}</Text>

          <div className='ui-product-card__price'>
            <Text className='ui-product-card__price-value'>
              {modifiedPrice[0]}
              {modifiedPrice?.[1] && (<sup>.{modifiedPrice[1]}</sup>)}</Text>
            <span>
              {/* <Text className='ui-product-card__currency'> </Text> */}
              {!label2 && (<Text className='ui-product-card__frequency'>{frequency}</Text>)}
              {label2 && (<>
                <Text className={cn('ui-product-card__frequency', 'ui-product-card__annual_frequency')}>{frequency}</Text>
                <Text className='ui-product-card__label2'>
                  {label2}
              </Text>
              </>)}
            </span>
          </div>
          {/* <Text className='ui-product-card__label2'>{label2}</Text> */}
          <div className={cn("ui-product-card__add_cta")}>
            <Option
              id={`${productName.replaceAll(' ', '-')}-Option`}
              label={optionLabel}
              active={true}
              disabled={shouldDisable}
              onClick={selectPlanProps}
            />
          </div>

          <Divider />
          
          <div>
            {isLpmPlus && (<Text className='ui-product-card__lpmplus'>Everything in Lifetime Protection plan +</Text>)}
            <div>
              {productBenefits.map((item, index) => {
                if (this.state.readMore || index <= 2) {
                  return (<div className="ui-product-card__benefits" key={`${productName.replaceAll(' ', '-')}-Benefits-${index}`}>
                    <Icon type='done' />
                    <span>{item}</span>
                  </div>)
                }
              })}
            </div>
            {(productBenefits.length > 3) && (<div className="ui-product-card__benefits" onClick={() => this.readMoreHandler()}>
                {!this.state.readMore ? <Icon type='expand_more' /> : <Icon type='expand_less' /> }
                <span className='ui-product-card__expand'>{!this.state.readMore ? 'View more benefits' : 'Show less'}</span>
            </div>
            )}
          </div>
        </PaperContent>
      </Paper>
    )
  }
}