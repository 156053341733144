import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperHeadline,
  PaperContent,
  ToggleButtonGroup,
  ProductCard,
  Icon
} from '../../'

import { SubsPetcademy, SubsVetHelpline } from '../SvgIcons'



export class NewPurchasePlanCard extends React.PureComponent {
  static propTypes = {
    annualProtectionOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      label: PropTypes.string,
      shouldDisable: PropTypes.bool,
      onClick: PropTypes.func,
      mostPopular: PropTypes.bool,
      productName: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.string,
      frequency: PropTypes.string,
      productBenefits: PropTypes.array
    }),
    isAlreadyPurchased: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    lifeTimeProtectionOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      label: PropTypes.string,
      shouldDisable: PropTypes.bool,
      onClick: PropTypes.func,
      mostPopular: PropTypes.bool,
      productName: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.string,
      frequency: PropTypes.string,
      productBenefits: PropTypes.array
    }),

    lifeTimeProtectionPlusOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      label: PropTypes.string,
      shouldDisable: PropTypes.bool,
      onClick: PropTypes.func,
      mostPopular: PropTypes.bool,
      productName: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.string,
      frequency: PropTypes.string,
      productBenefits: PropTypes.array
    }),

    directConnectOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      label: PropTypes.string,
      shouldDisable: PropTypes.bool,
      onClick: PropTypes.func,
      mostPopular: PropTypes.bool,
      productName: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.string,
      frequency: PropTypes.string,
      productBenefits: PropTypes.array,
      shouldDisplay: PropTypes.bool,
    }),

    petcademyOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      label: PropTypes.string,
      shouldDisable: PropTypes.bool,
      onClick: PropTypes.func,
      mostPopular: PropTypes.bool,
      productName: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.string,
      frequency: PropTypes.string,
      productBenefits: PropTypes.array,
      shouldDisplay: PropTypes.bool,
    }),

    petMedAlertOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      label: PropTypes.string,
      shouldDisable: PropTypes.bool,
      onClick: PropTypes.func,
      mostPopular: PropTypes.bool,
      productName: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.string,
      frequency: PropTypes.string,
      productBenefits: PropTypes.array,
      shouldDisplay: PropTypes.bool,
    }),

    vetHelplineOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      label: PropTypes.string,
      shouldDisable: PropTypes.bool,
      onClick: PropTypes.func,
      mostPopular: PropTypes.bool,
      productName: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.string,
      frequency: PropTypes.string,
      productBenefits: PropTypes.array,
      shouldDisplay: PropTypes.bool,
    }),

    productTypeProps: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      label: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      id: PropTypes.string
    }),


    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
  }

  render() {
    const {
      isAlreadyPurchased,
      annualProtectionOptionProps,
      lifeTimeProtectionOptionProps,
      lifeTimeProtectionPlusOptionProps,
      productTypeProps,
      directConnectOptionProps,
      petcademyOptionProps,
      petMedAlertOptionProps,
      vetHelplineOptionProps,
      id,
      className,
      style,
      refProp
    } = this.props


    let isSubscriptionProducts = productTypeProps.value === 'monthly' ? true : false;

    return (
      <Paper
        id={id}
        className={cn("ui-new-purchase-plan-card", className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>24Petwatch Memberships</PaperHeadline>

        <PaperContent>
          {!isAlreadyPurchased && (<ToggleButtonGroup
            {...productTypeProps}
          />)}
          {(!isSubscriptionProducts && !isAlreadyPurchased) && (<div className={cn('ui-new-purchase-plan-card__product')}>
            <ProductCard
              className={cn({ 'ui-new-purchase-plan-card_most-popular': lifeTimeProtectionPlusOptionProps.mostPopular })}
              optionLabel={lifeTimeProtectionOptionProps.label.toLowerCase() !== 'add to cart'
                ? (<div className="ui-new-purchase-plan-card__add_to_cart">
                  <Icon type='done' />
                  <span>{lifeTimeProtectionOptionProps.label}</span>
                </div>)
                : "Add to Cart"}
              selectPlanProps={lifeTimeProtectionOptionProps.onClick}
              productBenefits={[
                "Connect with your pet's finder and arrange a quick, safe reunion using DirectConnect.",
                "Receive a $25 Petco coupon, redeemable for in-store purchases.",
                "Reach veterinary professionals anytime by phone, email or live chat powered by whiskerDocs® (complimentary for 1 year)",
                "With 24PetMedAlert®, critical medical and behavioral information will be shared with the shelter or vet the pet is brought to when found (complimentary for 1 year)",
                "Access to Lost Pet Recovery Specialists who can quickly launch the process of finding your pet",
                "Save on pet-sitting and dog walking services with a $30 Rover coupon."
              ]}
              {...lifeTimeProtectionOptionProps}
            ></ProductCard>
            <ProductCard
              className={cn({ 'ui-new-purchase-plan-card_most-popular': lifeTimeProtectionOptionProps.mostPopular })}
              optionLabel={lifeTimeProtectionPlusOptionProps.label.toLowerCase() !== 'add to cart'
                ? (<div className="ui-new-purchase-plan-card__add_to_cart">
                  {lifeTimeProtectionPlusOptionProps.label.toLowerCase() !== 'upgrade' && <Icon type='done' />}
                  <span>{lifeTimeProtectionPlusOptionProps.label}</span>
                </div>)
                : "Add to Cart"}
              selectPlanProps={lifeTimeProtectionPlusOptionProps.onClick}
              productBenefits={[
                "Customized metal ID tags with all of your contact information to make reunification easier",
                "Customized training with easy to follow video lessons for you and your pet, powered by Petcademy"
              ]}
              {...lifeTimeProtectionPlusOptionProps}
            ></ProductCard>
          </div>)}

          {isSubscriptionProducts && (<div className={cn('ui-new-purchase-plan-card__monthly-product')}>
            {directConnectOptionProps?.shouldDisplay && <ProductCard
              optionLabel={(directConnectOptionProps.label.toLowerCase() !== 'add to cart')
                ? (<div className="ui-new-purchase-plan-card__add_to_cart">
                  <Icon type='done' />
                  <span>{directConnectOptionProps.label}</span>
                </div>)
                : "Add to Cart"}
              selectPlanProps={directConnectOptionProps.onClick}
              productBenefits={[
                "Easily connect with your pet’s finder to bring them home quickly.",
                "Get personalized support with reps who stay on the line to arrange your pet's safe return."
              ]}
              {...directConnectOptionProps}
            ></ProductCard>}
            {vetHelplineOptionProps?.shouldDisplay && <ProductCard
              logo={<SubsVetHelpline />}
              optionLabel={(vetHelplineOptionProps.label.toLowerCase() !== 'add to cart')
                ? (<div className="ui-new-purchase-plan-card__add_to_cart">
                  <Icon type='done' />
                  <span>{vetHelplineOptionProps.label}</span>
                </div>)
                : "Add to Cart"}
              selectPlanProps={vetHelplineOptionProps.onClick}
              productBenefits={[
                "Enjoy peace of mind and let the professionals help you avoid unnecessary & expensive visits to the vet or ER.",
                "Access to a digital health library with thousands of educational articles."
              ]}
              {...vetHelplineOptionProps}
            ></ProductCard>}
            {petMedAlertOptionProps?.shouldDisplay && <ProductCard
              optionLabel={(petMedAlertOptionProps.label.toLowerCase() !== 'add to cart')
                ? (<div className="ui-new-purchase-plan-card__add_to_cart">
                  <Icon type='done' />
                  <span>{petMedAlertOptionProps.label}</span></div>)
                : "Add to Cart"}
              selectPlanProps={petMedAlertOptionProps.onClick}
              productBenefits={[
                "Manage and update ongoing medical conditions that require special treatment, including medications, allergies, & behavioral issues.",
                "If your pet is found and reported to our Lost Pet Recovery, and you’re unavailable, we’ll relay your pet’s critical information as needed."
              ]}
              {...petMedAlertOptionProps}
            ></ProductCard>}
            {petcademyOptionProps?.shouldDisplay && <ProductCard
              logo={<SubsPetcademy />}
              optionLabel={(petcademyOptionProps.label.toLowerCase() !== 'add to cart')
                ? (<div className="ui-new-purchase-plan-card__add_to_cart">
                  <Icon type='done' />
                  <span>{petcademyOptionProps.label}</span>
                </div>)
                : "Add to Cart"}
              selectPlanProps={petcademyOptionProps.onClick}
              productBenefits={[
                "Customized training plans based on your pet’s age, history, & unique needs.",
                "Unlimited access to Petcademy community & licensed trainers."
              ]}
              {...petcademyOptionProps}
            ></ProductCard>}
            {annualProtectionOptionProps?.shouldDisplay && <ProductCard
              annualClass={"ui-product-card__annual"}
              optionLabel={(annualProtectionOptionProps.label.toLowerCase() !== 'add to cart')
                ? (<div className="ui-new-purchase-plan-card__add_to_cart">
                  <Icon type='done' />
                  <span>{annualProtectionOptionProps.label}</span>
                </div>)
                : "Add to Cart"}
              selectPlanProps={annualProtectionOptionProps.onClick}
              productBenefits={[
                "Connect with your pet's finder and arrange a quick, safe reunion using DirectConnect.",
                "Access to Lost Pet Recovery Specialists who can quickly launch the process of finding your pet"
              ]}
              {...annualProtectionOptionProps}
            ></ProductCard>}
          </div>)}
        </PaperContent>
      </Paper>
    )
  }
}
